import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./buildOnCeler.scss"
import cBridgeSDKcBridgeSDKPng from "../../images/home/cBridgeSDKcBridgeSDK.png"
import CelerInterchainMessageSDK from "../../images/home/CelerInterchainMessageSDK.png"
import goRight from "../../images/home/goRightWhite.svg"
import { homeLinks } from "../../constants"
import UseIsMobile from "../../hooks/UseIsMobile"
/* eslint-disable */
const list = [
  "Cross-chain yield farming",
  "One-click one-tx cross-chain swaps",
  "Metaverse games that use items from multiple chains",
  "Cross-chain state synchronization",
  "Cross-chain fee aggregation",
  "Token bridging",
  "Cross-chain liquidity provision and management",
  "NFT bridging",
  "Cross-chain NFT bidding and purchasing",
  "Cross-chain NFT collateralization",
  "Cross-chain governance",
]
let longList = list
for (let i = 0; i < 20; i++) {
  longList = longList.concat(list)
}

export default function BuildOnCeler() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  const buildOnCelerList = [
    {
      name: "Celer IM SDK",
      subTitle: "Build exciting inter-chain dApps such as:",
      descs: isMobile ? list : longList,
      img: CelerInterchainMessageSDK,
      linkList: [
        {
          linkName: "SDK Docs",
          linkHref: homeLinks.buildOnCeler.celerInterChainMessageSDK.sdkDoc,
        },
        {
          linkName: "Talk to a Builder",
          linkHref:
            homeLinks.buildOnCeler.celerInterChainMessageSDK.partnerOnboard,
        },
      ],
      isScroll: true,
    },
    {
      name: "cBridge SDK",
      subTitle: " ",
      descs: [
        "Integrate cBridge as part of your application workflow",
        "Integrate cBridge SDK for seamless asset bridging within your dApp",
        "Enable your protocol’s token for multi-chain expansion",
      ],
      img: cBridgeSDKcBridgeSDKPng,
      linkList: [
        {
          linkName: "SDK Docs",
          linkHref: homeLinks.buildOnCeler.cBridgeSDK.sdkDoc,
        },
        {
          linkName: "List your token",
          linkHref: homeLinks.buildOnCeler.cBridgeSDK.listYourToken,
        },
      ],
      isScroll: false,
    },
  ]
  return (
    <div className="buildOnCeler-bg">
      <div className="buildOnCeler">
        <ScrollOverPack playScale="25vh" id="0">
          <div className="buildOnCeler-top">
            <MyQueueAnim duration={3000} type="bottom">
              <div>Build on Celer</div>
            </MyQueueAnim>
          </div>

          <div className="buildOnCeler-bottom">
            {buildOnCelerList.map((item, index) => {
              return (
                <div className="buildOnCeler-bottom-content" key={item.name}>
                  <MyQueueAnim
                    duration={3000}
                    type="bottom"
                    delay={300}
                    key={item.name}
                  >
                    <div className="content-icon" key={item.name}>
                      <img src={item.img} alt="" />
                    </div>
                  </MyQueueAnim>

                  <MyQueueAnim
                    delay={800}
                    duration={3000}
                    type="bottom"
                    key={item.names + "1"}
                  >
                    <div key={item.names + "1"}>
                      <div className="content-title">{item.name}</div>
                      <div className="content-subTitle">{item.subTitle}</div>
                      <div className="content-desc">
                        <ul
                          className={
                            item.isScroll && !isMobile
                              ? "desc-list desc-list-scroll"
                              : "desc-list"
                          }
                        >
                          {item.descs.map((descItem, index) => {
                            return (
                              <li key={index} id={index}>
                                {descItem}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div className="content-link">
                        {item.linkList.map((link, index) => {
                          return (
                            <div
                              className="content-link-item"
                              onClick={() => {
                                window.open(link.linkHref, "_blank")
                              }}
                              key={index}
                            >
                              {link.linkName}{" "}
                              <img alt="" src={goRight} with={32} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </MyQueueAnim>
                </div>
              )
            })}
          </div>
        </ScrollOverPack>
      </div>
    </div>
  )
}
