import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./intro.scss"
import fastPng from "../../images/home/fast.png"
import securePng from "../../images/home/secure.png"
import lowCostPng from "../../images/home/lowCost.png"
import introImg from "../../images/home/intro-img.svg"
import goRight from "../../images/home/goRight.svg"
import UseIsMobile from "../../hooks/UseIsMobile"
import { homeLinks } from "../../constants"
/* eslint-disable */

const iconsList = [
  { icon: fastPng, text: "Fast" },
  { icon: securePng, text: "Secure" },
  { icon: lowCostPng, text: "Low cost" },
]

const descText =
  "Celer is a blockchain interoperability protocol enabling a one-click user experience accessing tokens, DeFi, GameFi, NFTs, governance, and more across multiple chains. Developers can build inter-chain-native dApps using the Celer Inter-chain Messaging SDK to gain access to efficient liquidity utilization, coherent application logic, and shared states. Users of Celer-enabled dApps will enjoy the benefits of a diverse multi-blockchain ecosystem with the simplicity of a single-transaction UX, all from a single chain."

export default function Intro() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="home-intro-bg">
      <div className="intro-top">
        {iconsList.map((item, index) => {
          return (
            <div className="home-icon" key={index}>
              <img src={item.icon} alt="" />
              <span>{item.text}</span>
            </div>
          )
        })}
      </div>
      <div className="intro-content-out">
        <ScrollOverPack
          playScale="15vh"
          id="0"
          style={{ width: "100%", maxWidth: 1300 }}
        >
          <MyQueueAnim
            duration={3000}
            type="bottom"
            style={{ width: "100%", maxWidth: 1300 }}
          >
            <div className="intro-content" key="intro-content">
              <div className="content-left">
                <div className="content-left-content">
                  <div className="left-title" key="left-title">
                    What is Celer
                  </div>
                  <div className="left-desc" key="left-desc">
                    {descText}
                  </div>

                  <MyQueueAnim duration={2000} delay={300} type="bottom">
                    <div
                      className="left-link"
                      key="left-link"
                      onClick={() => {
                        window.open(homeLinks.intro.learnMore, "_blank")
                      }}
                    >
                      Learn More <img alt="" src={goRight} with={32} />
                    </div>
                  </MyQueueAnim>
                </div>
              </div>
              <div className="content-right">
                <div className="content-right-content">
                  <img src={introImg} alt="" key="introImg" />
                </div>
              </div>
            </div>
          </MyQueueAnim>
        </ScrollOverPack>
      </div>
    </div>
  )
}
