export const prepareTransferConfigs = _transferConfig => {
  var tokenSymbols = new Map()
  var transferConfig = _transferConfig
  var peggedBridges = transferConfig.pegged_pair_configs

  var multiBurnConfigs =
    prepareMultiburnConfigs(transferConfig.pegged_pair_configs) ?? []

  peggedBridges.forEach(bridge => {
    let orgChainBlackList = chainTokenBlackList.get(bridge.org_chain_id) ?? []
    let pegChainBlackList =
      chainTokenBlackList.get(bridge.pegged_chain_id) ?? []

    if (
      orgChainBlackList.includes(bridge.org_token.token.symbol) ||
      pegChainBlackList.includes(bridge.pegged_token.token.symbol)
    ) {
      return
    }
    tokenSymbols.set(bridge.org_token.token.symbol, bridge.org_token.icon)
    const pegToken = replaceTokenAddressForCanonicalTokenSwapIfNeeded(
      bridge.pegged_token,
      bridge.canonical_token_contract_addr
    )
    tokenSymbols.set(pegToken.token.symbol, pegToken.icon)
  })

  var bridgedChainIds = prepareChainIds(transferConfig)

  const supportedChains = transferConfig.chains.filter(chainInfo => {
    return bridgedChainIds.has(chainInfo.id)
  })

  supportedChains.forEach(chainInfo => {
    const id = chainInfo.id

    const peggedTokenSymbol = transferConfig.pegged_pair_configs
      .map(peggedPair => {
        if (peggedPair.org_chain_id === id) {
          return peggedPair.org_token.token.symbol
        } else if (peggedPair.pegged_chain_id === id) {
          return peggedPair.pegged_token.token.symbol
        } else {
          return ""
        }
      })
      .filter(symbol => {
        return symbol.length > 0
      })
    const burnConfigTokenSymbol = multiBurnConfigs
      ?.map(burnConfig => {
        if (burnConfig.burn_config_as_org.chain_id === id) {
          return burnConfig.burn_config_as_org.token.token.symbol
        } else if (burnConfig.burn_config_as_dst.chain_id === id) {
          return burnConfig.burn_config_as_dst.token.token.symbol
        } else {
          return ""
        }
      })
      .filter(symbol => {
        return symbol.length > 0
      })

    const peggedSymbol = peggedTokenSymbol.concat(burnConfigTokenSymbol)

    let chainToken = transferConfig.chain_token[id]
    if (!chainToken) {
      return
    }

    chainToken.token
      .filter(tokenInfo => {
        return !peggedSymbol.includes(tokenInfo.token.symbol)
      })
      .forEach(tokenInfo => {
        const symbol = tokenInfo.token.symbol
        const blackList = chainTokenBlackList.get(id)
        if (blackList?.includes(symbol)) {
          return
        }
        tokenSymbols.set(symbol, tokenInfo.icon)
      })
  })
  const newTokens = [...tokenSymbols.keys()]
    .sort()
    .map(symbol => tokenSymbols.get(symbol))
  return {
    chains: supportedChains,
    tokens: newTokens,
  }
}

const prepareMultiburnConfigs = pegged_pair_configs => {
  const configsLength = pegged_pair_configs.length
  const multiBurnConfigs = []

  for (let i = 0; i < configsLength; i++) {
    for (let j = i + 1; j < configsLength; j++) {
      const peggedConfigI = pegged_pair_configs[i]
      const peggedConfigJ = pegged_pair_configs[j]
      if (
        peggedConfigI.org_chain_id === peggedConfigJ.org_chain_id &&
        peggedConfigI.org_token.token.symbol ===
          peggedConfigJ.org_token.token.symbol
      ) {
        /// Only upgraded PegBridge can support multi burn to other pegged chain
        if (peggedConfigI.bridge_version === 2) {
          multiBurnConfigs.push({
            burn_config_as_org: {
              chain_id: peggedConfigI.pegged_chain_id,
              token: peggedConfigI.pegged_token,
              burn_contract_addr: peggedConfigI.pegged_burn_contract_addr,
              canonical_token_contract_addr:
                peggedConfigI.canonical_token_contract_addr,
              burn_contract_version: peggedConfigI.bridge_version,
            },
            burn_config_as_dst: {
              chain_id: peggedConfigJ.pegged_chain_id,
              token: peggedConfigJ.pegged_token,
              burn_contract_addr: peggedConfigJ.pegged_burn_contract_addr,
              canonical_token_contract_addr:
                peggedConfigJ.canonical_token_contract_addr,
              burn_contract_version: peggedConfigJ.bridge_version,
            },
          })
        }

        if (peggedConfigJ.bridge_version === 2) {
          multiBurnConfigs.push({
            burn_config_as_org: {
              chain_id: peggedConfigJ.pegged_chain_id,
              token: peggedConfigJ.pegged_token,
              burn_contract_addr: peggedConfigJ.pegged_burn_contract_addr,
              canonical_token_contract_addr:
                peggedConfigJ.canonical_token_contract_addr,
              burn_contract_version: peggedConfigJ.bridge_version,
            },
            burn_config_as_dst: {
              chain_id: peggedConfigI.pegged_chain_id,
              token: peggedConfigI.pegged_token,
              burn_contract_addr: peggedConfigI.pegged_burn_contract_addr,
              canonical_token_contract_addr:
                peggedConfigI.canonical_token_contract_addr,
              burn_contract_version: peggedConfigI.bridge_version,
            },
          })
        }
      }
    }

    return multiBurnConfigs
  }
}

const replaceTokenAddressForCanonicalTokenSwapIfNeeded = (
  tokenInfo,
  canonical_token_contract_addr
) => {
  if (canonical_token_contract_addr.length > 0) {
    /// Canonical Token Swap, should use canonical_token_contract_addr
    const tempTokenInfo = tokenInfo
    const tempToken = {
      symbol: tempTokenInfo.token.symbol,
      address: canonical_token_contract_addr,
      decimal: tempTokenInfo.token.decimal,
      xfer_disabled: tempTokenInfo.token.xfer_disabled,
    }

    const result = {
      token: tempToken,
      name: tempTokenInfo.name,
      icon: tempTokenInfo.icon,
      max_amt: tempTokenInfo.max_amt,
      balance: tempTokenInfo.balance,
    }

    return result
  }

  return tokenInfo
}

const prepareChainIds = transferConfig => {
  const allChainIds = transferConfig.chains.map(chain => {
    return chain.id
  })

  const bridgedIds = new Set()

  allChainIds.forEach(id1 => {
    if (bridgedIds.has(id1)) {
      return
    }
    allChainIds.forEach(id2 => {
      if (id1 === id2) {
        return
      }

      if (twoChainBridged(id1, id2, transferConfig)) {
        bridgedIds.add(id1)
        bridgedIds.add(id2)
      }
    })
  })

  return bridgedIds
}

const twoChainBridged = (chainId1, chainId2, transferConfig) => {
  let peggedBridged = false

  transferConfig.pegged_pair_configs.forEach(peggedPairConfig => {
    const bridged =
      (peggedPairConfig.org_chain_id === chainId1 &&
        peggedPairConfig.pegged_chain_id === chainId2) ||
      (peggedPairConfig.org_chain_id === chainId2 &&
        peggedPairConfig.pegged_chain_id === chainId1)
    peggedBridged = peggedBridged || bridged
  })

  /// Skip pool based bridge check if two chains have pegged bridge
  if (peggedBridged) {
    return true
  }

  const poolBasedTokensForChainId1 = transferConfig.chain_token[chainId1]
  const poolBasedTokensForChainId2 = transferConfig.chain_token[chainId2]

  let poolBasedBridged = false
  if (
    poolBasedTokensForChainId1 &&
    poolBasedTokensForChainId1 !== undefined &&
    poolBasedTokensForChainId2 &&
    poolBasedTokensForChainId2 !== undefined
  ) {
    const poolBasedTokenSymbolsForChainId1 = poolBasedTokensForChainId1.token
      .filter(tokenInfo => {
        return !tokenInfo.token.xfer_disabled
      })
      .map(tokenInfo => {
        return tokenInfo.token.symbol
      })
    poolBasedTokensForChainId2.token.forEach(tokenInfo => {
      poolBasedBridged =
        poolBasedBridged ||
        (poolBasedTokenSymbolsForChainId1.includes(tokenInfo.token.symbol) &&
          !tokenInfo.token.xfer_disabled)
    })
  }
  return poolBasedBridged || peggedBridged
}

const chainTokenBlackList = new Map([
  [1, ["IMX", "OOKI"]], // Ethereum Mainnet
  [56, ["BANANA", "sJADE", "IMX", "OOKI"]], // BNB
  [137, ["IMX"]], // Polygon
  [250, ["IMX"]], // Fantom
  [42161, ["MUX", "IMX"]], // Arbitrum
  [43114, ["IMX"]], // Avalanche
  [1285, ["IMX"]], // Moonriver
  [1666600000, ["IMX"]], // Harmony
  [16350, ["BANANA"]],
])
