import React, { useState, useEffect } from "react"
// import axios from "axios"
// import { supportChainsFetchUrl } from "../../constants"
import { prepareTransferConfigs } from "../../utils/transferConfigAnalyzer"

import "./supportedChains.scss"
import UseIsMobile from "../../hooks/UseIsMobile"
import data from "./data.json"

export default function SupportedChains() {
  const [assetInfo, setAssetInfo] = useState()
  const [tokenList1, setTokenList1] = useState([])
  const [tokenList2, setTokenList2] = useState([])
  const [isMobile] = UseIsMobile()
  useEffect(() => {
    if (!data) {
      return
    }
    // axios({
    //   method: "get",
    //   url: supportChainsFetchUrl,
    // })
    //   .then(res => {
    const list = prepareTransferConfigs(data).tokens
    setAssetInfo(prepareTransferConfigs(data))
    const length = list?.length
    const list1 = list?.slice(0, length / 2)
    const newList1 = list1
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
    const list2 = list?.slice(length / 2, length + 1)
    const newList2 = list2
      .concat(list2)
      .concat(list2)
      .concat(list2)
      .concat(list2)
      .concat(list2)
      .concat(list2)
    setTokenList1(newList1)
    setTokenList2(newList2)
    // })
    // .catch(e => {
    //   console.log(e)
    // })
  }, [])

  const renderChainsSupported = () => {
    const list1 = assetInfo?.chains || []
    const newList = list1
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
      .concat(list1)
    if (isMobile) {
      return (
        <div className="supportedChains">
          <div className="supportedChains-title">
            {assetInfo?.chains.length} Chains
            <div> Supported</div>
          </div>
          <div className="mobile-content">
            <div className="mobile-content-body">
              {newList?.map((chain, index) => {
                return <img src={chain.icon} alt="" key={index} />
              })}
            </div>
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="supportedChains">
          <div className="supportedChains-title">
            {assetInfo?.chains.length} Chains Supported
          </div>
          <div className="supportedChains-content">
            <div className="chainRowup">
              {newList?.map((chain, index) => {
                return <img src={chain.icon} alt="" key={index} />
              })}
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderTokensSupported = () => {
    const renderList =
      tokenList1.length > tokenList2.length ? tokenList1 : tokenList2
    const isSameLength = tokenList1.length === tokenList2.length
    if (isMobile) {
      return (
        <div className="supportedChains">
          <div className="supportedChains-title">
            {assetInfo?.tokens.length} Tokens
            <div> Supported</div>
          </div>
          <div className="mobile-content mobileTokenHeight">
            <div className="mobile-content-body">
              {assetInfo?.tokens.map((token, index) => {
                return <img src={token} alt="" key={index} />
              })}
            </div>
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="supportedChains tokensHeight">
          <div className="supportedChains-title">
            {assetInfo?.tokens.length} Tokens Supported
          </div>
          <div className="supportedChains-content2">
            <div className="rowup">
              {renderList?.map((token, index) => {
                return !isSameLength && index + 1 === renderList?.length ? (
                  <div key={token}>
                    <img src={token} alt="" />
                  </div>
                ) : (
                  <div key={index} className="tokenItem">
                    <img src={tokenList1[index]} alt="" />
                    <img src={tokenList2[index]} alt="" className="lowToken" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="supportedChainsAndTokens">
      {renderChainsSupported()}
      <div className="space"></div>
      {renderTokensSupported()}
    </div>
  )
}
