import React, { useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import FirstPageText from "../components/home/FirstPageText"
import Intro from "../components/home/Intro"
// import Ecosystem from "../components/home/Ecosystem"
import Ecosystem from "../components/home/NewEcoSystem"
import BuildOnCeler from "../components/home/BuildOnCeler"
import SupportedChains from "../components/home/SupportedChains"

export default function IndexPage() {
  useEffect(() => {
    window.onbeforeunload = function() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      document.body.style.background = "#fff"
      document.body.style.opacity = 0
    }
  }, [])

  return (
    <Layout>
      <SEO title="Celer Network" />
      <FirstPageText />
      <Intro />
      <Ecosystem />
      <BuildOnCeler />
      <SupportedChains />
    </Layout>
  )
}
