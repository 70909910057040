import React from "react"
import QueueAnim from "rc-queue-anim"
import "./firstPageText.scss"
import UseIsMobile from "../../hooks/UseIsMobile"

export default function FirstPageText() {
  const [isMobile] = UseIsMobile()
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="home-hero">
      <div className="hero-title">
        <MyQueueAnim duration={2000} type="bottom" key="1">
          <div className="hero-main-title" key="1">
            Blockchains Connected
          </div>
        </MyQueueAnim>
        <MyQueueAnim duration={3000} type="bottom" key="2">
          <div className="hero-sub-title" key="1">
            Every dApp, Every Asset, Every User
          </div>
        </MyQueueAnim>
      </div>
    </div>
  )
}
