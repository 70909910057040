import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
// import { navigate } from "gatsby"
import "./newEcoSystem.scss"
import stateGuardianNetwork from "../../images/home/stateGuardianNetwork.svg"
import celerImMin from "../../images/home/celerIm-min.svg"
import celerBridge from "../../images/home/celerBridge.svg"
import cBridgeMin from "../../images/home/cBridge-min.svg"
import petiContentImg from "../../images/home/petiContentImg.svg"
import petiMin from "../../images/home/peti-min.svg"
import peti from "../../images/home/peti.svg"
import celerIm from "../../images/home/celerIm.svg"
import goRight from "../../images/home/goRight.svg"
import cBridge from "../../images/home/cbridge.svg"
import { homeLinks } from "../../constants"
import UseIsMobile from "../../hooks/UseIsMobile"
/* eslint-disable */

export default function Ecosystem() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  const ecosystemList = [
    {
      imageInfo: {
        imgSrc: isMobile ? celerImMin : stateGuardianNetwork,
        imgBgColor: "#EEEEEE",
      },
      descInfo: {
        title: (
          <div>
            <img
              alt=""
              src={celerIm}
              className="eco-system-content-text-title-img"
            />{" "}
            Celer IM
          </div>
        ),
        subTitle:
          "Simple, secure, and seamless multi-blockchain interoperability",
        bgColor: "rgba(48, 149, 238, 1)",
        desc:
          "Celer IM (Inter-chain Messaging framework) fundamentally changes how multi-blockchain dApps are built and used. Instead of deploying multiple isolated copies of smart contracts on different blockchains, developers can build inter-chain-native dApps with efficient liquidity utilization, coherent application logic, and shared states. IM pairs Celer's Message Bus contracts deployed on different chains with the State Guardian Network, a Tendermint-based relayer blockchain, to send a message or invoke a smart contract function cross-chain.",
        linkList: [
          {
            text: "Learn more",
            link: "https://im-docs.celer.network/developer/celer-im-overview",
            type: "",
          },
          {
            text: "Use Cases",
            link: "/useCases#top",
            type: "router",
          },
        ],
        useAppInfoList: [],
      },
    },
    {
      imageInfo: {
        imgSrc: isMobile ? cBridgeMin : celerBridge,
        imgBgColor: "#EEEEEE",
      },
      descInfo: {
        title: (
          <div>
            <img
              alt=""
              src={cBridge}
              className="eco-system-content-text-title-img"
            />{" "}
            cBridge
          </div>
        ),
        subTitle: "Fast, secure and low-cost multi-blockchain asset bridge",
        bgColor: "#58C971",
        desc:
          "Celer cBridge is a decentralized and non-custodial asset bridge that supports token transfer across 40+ blockchains and layer-2 rollups. Built on top of the Celer Inter-chain Messaging Framework, cBridge has processed more than $14B cross-chain asset transfer volume for more than 540K unique users, and is quickly growing and expanding into more blockchains and layer-2s.",
        link: "https://cbridge.celer.network/",
        linkList: [
          {
            text: "Learn more",
            link: homeLinks.useCeler.celerBridge.learnMore,
            type: "",
          },
          {
            text: "List Token",
            link: homeLinks.useCeler.celerBridge.listToken,
            type: "",
          },
        ],
        useAppInfoList: [
          {
            useAppLink: homeLinks.useCeler.celerBridge.useApp,
            text: "Use APP",
            type: "",
          },
        ],
      },
    },
    {
      imageInfo: {
        imgSrc: isMobile ? petiMin : petiContentImg,
        imgBgColor: "#EEEEEE",
      },
      descInfo: {
        title: (
          <div>
            <img
              alt=""
              src={peti}
              className="eco-system-content-text-title-img"
            />{" "}
            Peti
          </div>
        ),
        subTitle:
          "High liquidity efficiency, MEV protection, and zero slippage",
        bgColor: "rgba(233, 184, 250, 1)",
        desc:
          "Peti is an omnichain liquidity protocol that provides the smoothest experiences for traders and the best liquidity efficiency for institutional market makers. For traders, it is often expensive, if not impossible, to execute large-size omnichain trades due to the high slippages of AMM pricing combined with limited depth of the liquidity pools of cross-chain bridges. Using Peti, traders now can utilize the “just-in-time” liquidity to execute omnichain trades with zero slippage.",
        link: "https://github.com/celer-network/celer-light-client",
        linkList: [
          {
            text: "Learn more",
            link: "https://docs.peti.trade/overview/introduction",
          },
        ],
      },
    },
  ]
  return (
    <div className="eco-system">
      <div className="eco-system-title">
        <ScrollOverPack playScale={0.23} always={false} key="eco-system">
          <MyQueueAnim duration={2000} type="bottom">
            <div key="eco-system-title">Use Celer</div>
          </MyQueueAnim>
        </ScrollOverPack>
      </div>

      <div className="eco-system-contents">
        {ecosystemList?.map((item, index) => {
          return (
            <div className="eco-system-content" key={index}>
              <div
                className={`eco-system-content-img ${
                  index % 2 === 0 ? "order-sm-1" : "order-sm-2"
                }`}
              >
                <div className="eco-system-content-img-content">
                  <ScrollOverPack
                    playScale={0.15 + index * 0.02}
                    key={"text" + index}
                    always={false}
                  >
                    <MyQueueAnim duration={2000} type="bottom">
                      <img
                        src={item.imageInfo.imgSrc}
                        alt=""
                        key={"ecosystem" + index.toString()}
                      />
                    </MyQueueAnim>
                  </ScrollOverPack>
                </div>
              </div>

              <div
                className={`eco-system-content-text-bg ${
                  index % 2 === 0 ? "order-sm-2" : "order-sm-1"
                }`}
                style={{ background: item.descInfo.bgColor }}
              >
                <div className={`eco-system-content-text`}>
                  <ScrollOverPack
                    playScale={0.25 + index * 0.02}
                    key={"text2" + index}
                    always={false}
                  >
                    <MyQueueAnim duration={2000} type="bottom" delay={300}>
                      <div
                        className="eco-system-content-text-title"
                        key={"ecosystems1" + index.toString()}
                      >
                        {item.descInfo.title}
                      </div>
                      <div
                        className="eco-system-content-text-subTitle"
                        key={"ecosystems2" + index.toString()}
                      >
                        {item.descInfo.subTitle}
                      </div>
                      <div
                        className="eco-system-content-text-content"
                        key={"ecosystems3" + index.toString()}
                      >
                        {item.descInfo.desc}
                      </div>
                      <div
                        className="eco-system-content-text-links"
                        key={"ecosystems4" + index.toString()}
                      >
                        {!isMobile && item.descInfo.useAppInfoList?.length > 0 && (
                          <div className="eco-system-btn-links">
                            {item.descInfo.useAppInfoList?.map(
                              (useAppInfo, index) => {
                                return (
                                  <div
                                    className="eco-system-btn-links-item"
                                    key={index}
                                  >
                                    <a
                                      href={useAppInfo.useAppLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {useAppInfo.text}
                                    </a>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        )}
                        <div className="eco-system-text-links">
                          {item.descInfo.linkList?.map((linkItem, index) => {
                            return (
                              <div
                                className="eco-system-text-links-item"
                                onClick={() => {
                                  // if (linkItem.type === "router") {
                                  //   navigate(linkItem.link, {
                                  //     replace: false,
                                  //   })
                                  // } else {
                                  window.open(linkItem.link, "_blank")
                                  // }
                                }}
                                key={index}
                              >
                                {linkItem.text}{" "}
                                <img alt="" src={goRight} with={32} />
                              </div>
                            )
                          })}
                        </div>
                        {isMobile && (
                          <div className="eco-system-btn-links">
                            {item.descInfo.useAppInfoList?.map(
                              (useAppInfo, index) => {
                                return (
                                  <div
                                    className="eco-system-btn-links-item2"
                                    key={index}
                                  >
                                    <a
                                      href={useAppInfo.useAppLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {useAppInfo.text}
                                    </a>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </MyQueueAnim>
                  </ScrollOverPack>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
